import { connect, OutputNode, ToneAudioNode, ToneAudioNodeOptions } from "../core/context/ToneAudioNode";
import { Decibels } from "../core/type/Units";
import { Volume } from "../component/channel/Volume";
import { optionsFromArguments } from "../core/util/Defaults";
import { noOp } from "../core/util/Interface";
// import { assert } from "../core/util/Debug";
import { Param } from "../core/context/Param";
import { readOnly } from "../core/util/Interface";
import { isString } from "../core/util/TypeCheck";

export interface MediaElementSourceOptions extends ToneAudioNodeOptions {
	url?: string | HTMLMediaElement;
	volume: Decibels;
	onload: (audioNode?: HTMLMediaElement) => void;
	onerror: (error: Error) => void;
	mute: boolean;
}
/**
 * MediaElementSource uses createMediaElementAudioSource to use an audio tag for input. 
 * 
 * @example
 * const meter = new Tone.Meter();
 * 
 * @category Source
 */

export class MediaElementSource extends ToneAudioNode<MediaElementSourceOptions> {

	readonly name: string = "MediaElementSource";

	readonly input: undefined;
	readonly output: OutputNode;

	/**
	 * The MediaElementNode
	 */
	// private _mediaElement?: MediaElementAudioSourceNode;

	/**
	 * The HTMLMediaElement with the loaded audio file.
	 */
	// private _audioNode?: HTMLMediaElement;

	/**
	 * The output volume node
	 */
	private _volume: Volume;

	/**
	 * The volume of the output in decibels.
	 */
	readonly volume: Param<"decibels">;

	/**
	 * @param volume The level of the input in decibels
	 */

	onload: (audioNode: HTMLMediaElement) => void = noOp;
	// TODO: might need to fix the onload?

	constructor(
		url: string | HTMLMediaElement,
		volume?: Decibels,
		onload?: (audioNode?: HTMLMediaElement) => void,
		onerror?: (error: Error) => void
	);
	constructor(options?: Partial<MediaElementSourceOptions>);
	constructor() {

		super(optionsFromArguments(MediaElementSource.getDefaults(), arguments, ["volume"]));
		const options = optionsFromArguments(
			MediaElementSource.getDefaults(), 
			arguments, 
			["url", "volume", "onload", "onerror"]
		);

		this._volume = this.output = new Volume({
			context: this.context,
			volume: options.volume,
		});
		this.volume = this._volume.volume;
		readOnly(this, "volume");
		this.mute = options.mute;

		this.onload = options.onload;

		if (isString(options.url)) {
			// initiate the download
			// this.load(options.url).catch(options.onerror);
		} else if (options.url) {
			this.setAudioNode(options.url);
		}
	}

	static getDefaults(): MediaElementSourceOptions {
		return Object.assign(ToneAudioNode.getDefaults(), {
			mute: false,
			volume: 0,
			onerror: noOp,
			onload: noOp,
		});
	}

	/**
	 * Pass in an AudioNode to set the value of this buffer.
	 */
	setAudioNode(audioNode: HTMLMediaElement): this {
		if (audioNode instanceof HTMLMediaElement) {
			const context = this.context;
			const mediaElement = context.createMediaElementSource(audioNode);
			connect(mediaElement, this.output);
			// this._mediaElement = mediaElement;
			// this._audioNode = audioNode;
		}
		return this;
	}

	// /**
	//  * Open the media stream. If a string is passed in, it is assumed
	//  * to be the label or id of the stream, if a number is passed in,
	//  * it is the input number of the stream.
	//  * @param  labelOrId The label or id of the audio input media device.
	//  *                   With no argument, the default stream is opened.
	//  * @return The promise is resolved when the stream is open.
	//  */
	// async open(labelOrId?: string | number): Promise<this> {
	// 	assert(UserMedia.supported, "UserMedia is not supported");
	// 	// close the previous stream
	// 	if (this.state === "started") {
	// 		this.close();
	// 	}
	// 	const devices = await UserMedia.enumerateDevices();
	// 	if (isNumber(labelOrId)) {
	// 		this._device = devices[labelOrId];
	// 	} else {
	// 		this._device = devices.find((device) => {
	// 			return device.label === labelOrId || device.deviceId === labelOrId;
	// 		});
	// 		// didn't find a matching device
	// 		if (!this._device && devices.length > 0) {
	// 			this._device = devices[0];
	// 		}
	// 		assert(isDefined(this._device), `No matching device ${labelOrId}`);
	// 	}
	// 	// do getUserMedia
	// 	const constraints = {
	// 		audio: {
	// 			echoCancellation: false,
	// 			sampleRate: this.context.sampleRate,
	// 			noiseSuppression: false,
	// 			mozNoiseSuppression: false,
	// 		}
	// 	};
	// 	if (this._device) {
	// 		// @ts-ignore
	// 		constraints.audio.deviceId = this._device.deviceId;
	// 	}
	// 	const stream = await navigator.mediaDevices.getUserMedia(constraints);
	// 	// start a new source only if the previous one is closed
	// 	if (!this._stream) {
	// 		this._stream = stream;
	// 		// Wrap a MediaStreamSourceNode around the live input stream.
	// 		const mediaStreamNode = this.context.createMediaStreamSource(stream);
	// 		// Connect the MediaStreamSourceNode to a gate gain node
	// 		connect(mediaStreamNode, this.output);
	// 		this._mediaStream = mediaStreamNode;
	// 	}
	// 	return this;
	// }

	/**
	 * Mute the output.
	 * @example
	 * const mic = new Tone.UserMedia();
	 * mic.open().then(() => {
	 * 	// promise resolves when input is available
	 * });
	 * // mute the output
	 * mic.mute = true;
	 */
	get mute(): boolean {
		return this._volume.mute;
	}
	set mute(mute) {
		this._volume.mute = mute;
	}

	dispose(): this {
		super.dispose();
		this._volume.dispose();
		this.volume.dispose();
		return this;
	}

}
